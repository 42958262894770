import { render, staticRenderFns } from "./Rent.vue?vue&type=template&id=66cc1fae&scoped=true&"
import script from "./Rent.vue?vue&type=script&lang=js&"
export * from "./Rent.vue?vue&type=script&lang=js&"
import style0 from "./Rent.vue?vue&type=style&index=0&id=66cc1fae&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66cc1fae",
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QBtnDropdown,QBtnToggle,QInput,QPopupProxy,QDate,QIcon,QCheckbox,QBtn,QInfiniteScroll,QSpinnerDots,QDialog,QLayout,QHeader,QToolbar,QSpace,QPageContainer,QPage});qInstall(component, 'directives', {ClosePopup});
