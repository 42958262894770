<template>
  <div class="rent__body">
    <div class="phouse__search--filterbox">
      <!-- <div v-if="query.action !== 'favourite'" class="phouse__search--filterlist dif">
        <q-select use-input input-debounce="0" @input="cityNeighborhoodSelctInput" dense outlined  map-options emit-value class="filter__select--box mr10" v-model="filter.city_neighborhood.value" @filter="filterFn" :options="filter.city_neighborhood.options" :label="filter.city_neighborhood.label"></q-select>
        <q-btn-dropdown unelevated outline class="h40 filter__dropdown--box ml10"  ref="bedroomsRef" no-caps :label="filter.bedrooms.label">
          <q-btn-toggle @input="bedroomsInput" v-model="filter.bedrooms.value" :options="filter.bedrooms.options"></q-btn-toggle>
        </q-btn-dropdown>
        <q-btn-dropdown unelevated outline class="h40 filter__dropdown--box ml10"  ref="bathroomsRef" no-caps :label="filter.bathrooms.label">
          <q-btn-toggle @input="bathroomsInput" v-model="filter.bathrooms.value" :options="filter.bathrooms.options"></q-btn-toggle>
        </q-btn-dropdown>
        <q-btn-dropdown unelevated outline :class="{invisible:!filter.price.show,absolute:!filter.price.show}" ref="price"  no-caps class="h40 filter__dropdown--box ml10" :label="filter.price.label">
          <div class="w250 row q-col-gutter-md q-pa-xs">
            <q-select :label="$t('min')" class="col" dense @input="priceMinSelectInput" map-options emit-value outlined v-model="filter.price.minvalue" :options="filter.price.minoptions" />
            <q-select :label="$t('max')" class="col" dense @input="priceMaxSelectInput" outlined map-options emit-value v-model="filter.price.maxvalue" :options="filter.price.maxoptions" />
          </div>
        </q-btn-dropdown>
        <q-select @input="petSelctInput" dense outlined map-options emit-value class="filter__select--box ml10" v-model="filter.pet.value" :options="filter.pet.options" :label="filter.pet.label"></q-select>
        <q-input class="ml10" @clear="availRangClear" clearable :label="filter.avail.label" dense outlined v-model="availRang">
          <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
            <q-date mask="MM/DD/YYYY" v-model="filter.avail.value" range></q-date>
          </q-popup-proxy>
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer"></q-icon>
          </template>
        </q-input>
        <q-checkbox class="checkphoto__box ml10" @input="photoCheckInput" v-model="filter.photo.value" dense true-value="Y" false-value="" :label="filter.photo.label" />
      </div> -->
      <div class="moresearchModule" v-if="query.action !== 'favourite'">
        <ul>
          <li>
            <label class="label_title">{{$t('cityStreet')}}：</label>
            <div class="searchNum">
              <el-select @change="cityNeighborhoodSelctInput" size="mini" v-model="filter.city_neighborhood.value" :placeholder="$t('placeholderSelect')">
                <el-option
                  v-for="item in filter.city_neighborhood.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </li>        

          <li>
            <label class="label_title">{{$t('priceLabel')}}：</label>
            <div ref="price"  class="searchNum">
              <el-select size="mini" style="width:120px" v-model="filter.price.minvalue" :placeholder="$t('min')">
                <el-option
                  v-for="item in filter.price.minoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <span style="margin:0 10px;">{{$t('zhiM')}}</span>
              <el-select size="mini" style="width:120px"  v-model="filter.price.maxvalue" :placeholder="$t('max')">
                <el-option
                  v-for="item in filter.price.maxoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </li>

          
          <li>
            <label class="label_title">{{$t('filter.bedrooms.label')}}：</label>
            <div class="searchNum" ref="bedroomsRef">
              <el-radio-group @change="bedroomsInput" v-model="filter.bedrooms.value">
                <el-radio v-for="item in filter.bedrooms.options" :key="item.value" :label="item.value">{{item.label}}</el-radio>
              </el-radio-group>
            </div>
          </li>
          <li>
            <label class="label_title">{{filter.bathrooms.label}}：</label>
            <div class="searchNum" ref="bathroomsRef">
              <el-radio-group @change="bathroomsInput" v-model="filter.bathrooms.value">
                <el-radio v-for="item in filter.bathrooms.options" :key="item.value" :label="item.value">{{item.label}}</el-radio>
              </el-radio-group>
            </div>
          </li>
          <li>
            <label class="label_title">{{filter.pet.label}}：</label>
            <div class="searchNum" >
              <el-radio-group @change="petSelctInput" size="mini" v-model="filter.pet.value">
                <el-radio v-for="item in filter.pet.options" :key="item.value" :label="item.value">{{item.label}}</el-radio>
              </el-radio-group>
            </div>
          </li>
          <li>
            <label class="label_title">{{$t('otherLabel')}}：</label>
            <div class="searchNum">
              <el-switch
                @change="photoCheckInput"
                size="mini"
                v-model="filter.photo.value"
                active-color="#13ce66"
                inactive-color="#A6A6A6"
                active-value="Y">
              </el-switch>
              <span>{{filter.photo.label}}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="lh80" v-if="query.action === 'favourite'">
        <q-btn :to="{name:'buy',query:{action:'favourite'}}" class="mlr10" :label="$t('favourite.toBuyFavourite')" outline color="blue-7" />
        <q-btn :to="{name:'rentlist',query:{action:'favourite'}}" class="mlr10" :label="$t('favourite.toRentFavourite')" color="blue-7" />
      </div>
    </div>
    <div :style="viewStyle" class="phouse__view clearfix">
      <div class="phouse__list">
        <h3 class="title">{{$t('tigongfangyuan')}}</h3>
        <ul id="houserList">
          <q-infinite-scroll ref="infinitescroll" scroll-target="#houserList" @load="houseListLoad" :offset="50">
            <li v-for="(house,$index) in houseList" v-if="house" @mouseenter="phouseMouseEnter($index)" @click="clickDialog(house,$index)" class="phouse__item clearfix">
              <div class="phouse__item--thumb">
                <img :src="house.Photos && (Object.prototype.toString.call(house.Photos.Photo) === '[object Array]' ? house.Photos.Photo[0] : house.Photos.Photo)" alt="">
                <q-btn @click.stop="collectHouse(house.ID,$index,query.action)" class="phouse__item--collect" flat no-caps dense text-color="red-6" :icon="query.action === 'favourite' ? 'favorite':'favorite_border'"></q-btn>
<!--                <span v-if="house.UpdateDate" class="phouse__item&#45;&#45;info&#45;&#45;updateDate">{{house.zzUpdateDate}}</span>-->
              </div>
              <div class="phouse__item--info clearfix">
                <div class="phouse__item--price__box">
                  <span class="phouse__item--price__num">${{house.Price | price2thousandth}}/{{$t('rent.mo')}}</span>
                </div>
                <div class="phouse__item--desc">
                  <h3 class="phouse__item--info--name">{{house.StreetNumber}} {{house.Unit}} {{house.StreetName}} {{house.City}} </h3>
                  <div class="phouse__item--info--column phouse__item--info--location">
                    <span class="phouse__item--info--icon phouse__item--info--location--icon material-icons">location_on</span>
                    <span class="phouse__item--info--content">{{house.StreetNumber}}, {{house.Unit}},{{house.StreetName}} , {{house.City}}, {{house.State}} {{house.Zip}}</span>
                  </div>
                  <div class="phouse__item--info--column phouse__item--info--laws">
                    <span class="phouse__item--info--icon phouse__item--info--laws-icon material-icons">insert_chart</span>
                    <span class="phouse__item--info--content">{{house.Beds}} {{$t('detail.beds')}} | {{house.Baths}} {{$t('detail.baths')}} | {{house.SquareFootage}}{{$t('detail.sqft')}} | {{house.Laundry}}</span>
                  </div>
                  <!-- <div v-if="house.Parking" class="phouse__item--info--column phouse__item--info--listing">
                    <span class="phouse__item--info--icon material-icons">local_parking</span>
                    <span class="phouse__item--info--content">{{house.Parking && house.Parking.ParkingAvailability}},{{house.Parking && house.Parking.ParkingPrice}}</span>
                  </div> -->
                  <div class="phouse__item--info--column phouse__item--info--listing">
                    <span class="phouse__item--info--icon material-icons">calendar_today</span>
                    <span class="phouse__item--info--content">{{house.AvailableDate}}</span>
                  </div>
                </div>
              </div>
            </li>
            <div v-if="!isloading && !houseList.length" class="row justify-center q-my-md list__nodata">
              {{$t('detail.NODATA')}}
            </div>
            <template v-slot:loading>
              <div v-if="isloading" class="row justify-center q-my-md">
                <q-spinner-dots color="primary" size="40px" />
              </div>
            </template>
          </q-infinite-scroll>
        </ul>
      </div>
      <div id="phouseMap" v-if="query.action !== 'favourite'" class="phouse__map">
        <gmap-map
          :center="{lat: houseLatLng.length && houseLatLng[0].position.lat || 51.36,lng: houseLatLng.length && houseLatLng[0].position.lng || -0.05}"
          :zoom="13"
          map-type-id="roadmap"

        >
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen=false"
          ></gmap-info-window>
          <gmap-marker
            v-if="houseLatLng && houseLatLng.length"
            :key="index"
            v-for="(m,index) in houseLatLng"
            :position="m.position"
            :visible="true"
            :clickable="true"
            @click="gMapMarkerClick(m,index)"
            @mouseover="gMapMarkerOver(m,index)"
          >
          </gmap-marker>
        </gmap-map>
      </div>
      <div v-if="query.action === 'favourite'" class="favourite__detailbox">
        <div class="favourite__detailbox__img"><img src="../assets/images/4.jpg" alt=""></div>
        <div class="favourite__detailbox__text" v-html="$t('blog.rent.content')"></div>
      </div>
    </div>
    <q-dialog
      v-model="detailDialogStatus"
      maximized
    >
      <q-layout view="Lhh lpR fff" container class="bg-white phouse__pop">
        <q-header class="bg-grey-3">
          <q-toolbar>
            <!-- <q-icon color="grey-8" size="sm" name="location_on" /> -->
            <div class="text-h6 phouse__title" style="color:#1871FF !important; font-weight:600;">{{currentDetailInfo.StreetNumber}},{{currentDetailInfo.StreetName}},{{currentDetailInfo.City}},{{currentDetailInfo.Neighborhood}}</div><!----text-grey-8 text-weight-thin-->
            <q-space />
            <!-- <q-btn icon="close" flat round dense v-close-popup /> -->
            <q-btn style="padding: 0 10px;" :label="$t('closeBtn')" color="primary" v-close-popup />
          </q-toolbar>
        </q-header>
        <q-page-container>
          <q-page>
            <swiper
              :options="swiperOptions"
              v-if="currentDetailInfo.Photos && currentDetailInfo.Photos.Photo&& (Object.prototype.toString.call(currentDetailInfo.Photos.Photo) === '[object Array]')"
            >
              <swiper-slide
                v-for="item in currentDetailInfo.Photos && (Object.prototype.toString.call(currentDetailInfo.Photos.Photo) === '[object Array]' ? currentDetailInfo.Photos.Photo : currentDetailInfo.Photos)"
              >
                <img :src="item" alt="">
              </swiper-slide>
              <div slot="button-next" class="swiper-button-next"></div>
              <div slot="button-prev" class="swiper-button-prev"></div>
            </swiper>
            <div style="text-align: center" class="gralley" v-if="currentDetailInfo.Photos && (Object.prototype.toString.call(currentDetailInfo.Photos.Photo) === '[object String]')">
              <img style="max-width: 100%;" :src="currentDetailInfo.Photos.Photo" alt="">
            </div>
            <div class="phouse__detail--info q-pa-md">
              <div>
                <span class="price">${{currentDetailInfo.Price | price2thousandth}}</span>
                <q-btn @click.stop="collectHouse(currentDetailInfo.ID,currentIndex,query.action)" class="phouse__detail--collect" flat no-caps dense style="float: right;" :icon="query.action === 'favourite' ? 'favorite':'favorite_border'"></q-btn>
              </div>
              <div><span class="listing-status">{{(currentDetailInfo.Status)}}</span></div>
              <h3 class="phouse__detail--info--name clearfix text-h5 text-weight-light text-grey-8">
                <q-icon color="grey-8" size="sm" name="location_on" />
                {{currentDetailInfo.StreetNumber}},{{currentDetailInfo.StreetName}},{{currentDetailInfo.City}},{{currentDetailInfo.Neighborhood}}
              </h3>
              <div class="info">
                <q-icon color="grey-8" size="sm" style="margin-right: 10px; margin-left:-5px; vertical-align: top;" name="sensor_window" />
                <span class="phouse__detail--content--info-text--beds">{{currentDetailInfo.Beds}} {{$t('detail.beds')}}</span>
                <span class="phouse__detail--content--info-text--baths">{{currentDetailInfo.Baths}} {{$t('detail.baths')}}</span>
                <span v-if="currentDetailInfo.SquareFootage" class="phouse__detail--content--info-text--sqft">{{currentDetailInfo.SquareFootage}}{{$t('detail.sqft')}}</span>
              </div>
              <div v-if="currentDetailInfo.VirtualTours" style="margin-top: 30px">
                VR:
                <span style="vertical-align: -6px;" v-if="currentDetailInfo.VirtualTours">
                    <q-btn
                      v-if="currentDetailInfo.VirtualTours.VirtualTour && currentDetailInfo.VirtualTours.VirtualTour.length"
                      v-for="(VT, vtIndex) in currentDetailInfo.VirtualTours.VirtualTour"
                      @click="openVT(VT)"
                      style="margin-left: 5px;font-size: 8px;vertical-align: 7px;"
                      :label="(vtIndex + 1)"
                      outline
                      dark-percentage
                      unelevated
                      color="blue-14"
                      size="xs" />
                  </span>
              </div>
              <ul class="phouse__detail--content--info-content" style="padding: 0 50px;">
                <li class="phouse__detail--content--info-content--mls">ID# {{currentDetailInfo.ID}}</li>
                <li class="phouse__detail--content--info-content--mls">{{$t('Zip')}}# {{currentDetailInfo.Zip}}</li>
                <li class="phouse__detail--content--info-content--website">{{$t('AvailableDate')}}: {{currentDetailInfo.AvailableDate}}</li>
                <li class="phouse__detail--content--info-content--website">{{$t('IncludeHeat')}}: {{currentDetailInfo.IncludeHeat}}</li>
                <li class="phouse__detail--content--info-content--website">{{$t('IncludeGas')}}: {{currentDetailInfo.IncludeGas}}</li>
                <li class="phouse__detail--content--info-content--website">{{$t('Room')}}: {{currentDetailInfo.Room}}</li>
                <li class="phouse__detail--content--info-content--website">{{$t('Fee')}}: {{currentDetailInfo.Fee}}</li>
                <li class="phouse__detail--content--info-content--website" v-if="currentDetailInfo.Parking">{{$t('Parking')}}: {{currentDetailInfo.Parking.ParkingAvailability}}{{currentDetailInfo.Parking.ParkingPrice}}</li>
                <li class="phouse__detail--content--info-content--website">{{$t('IncludeElectricity')}}: {{currentDetailInfo.IncludeElectricity}}</li>
                <li class="phouse__detail--content--info-content--website">{{$t('IncludeHotWater')}}: {{currentDetailInfo.IncludeHotWater}}</li>
                <li class="phouse__detail--content--info-content--website">{{$t('BedInfo')}}: {{currentDetailInfo.BedInfo}}</li>
                <li class="phouse__detail--content--info-content--website">{{$t('Pet')}}: {{currentDetailInfo.Pet}}</li>
              </ul>
            </div>
          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>
  </div>
</template>

<script>

export default {
  name: 'Rent',
  data(){
    let priceOptions = [
      {
        "label": "600",
        "value": 600
      },
      {
        "label": "700",
        "value": 700
      },
      {
        "label": "800",
        "value": 800
      },
      {
        "label": "900",
        "value": 900
      },
      {
        "label": "1,000",
        "value": 1000
      },
      {
        "label": "1,100",
        "value": 1100
      },
      {
        "label": "1,200",
        "value": 1200
      },
      {
        "label": "1,300",
        "value": 1300
      },
      {
        "label": "1,400",
        "value": 1400
      },
      {
        "label": "1,500",
        "value": 1500
      },
      {
        "label": "1,600",
        "value": 1600
      },
      {
        "label": "1,700",
        "value": 1700
      },
      {
        "label": "1,800",
        "value": 1800
      },
      {
        "label": "1,900",
        "value": 1900
      },
      {
        "label": "2,000",
        "value": 2000
      },
      {
        "label": "2,100",
        "value": 2100
      },
      {
        "label": "2,200",
        "value": 2200
      },
      {
        "label": "2,300",
        "value": 2300
      },
      {
        "label": "2,400",
        "value": 2400
      },
      {
        "label": "2,500",
        "value": 2500
      },
      {
        "label": "2,600",
        "value": 2600
      },
      {
        "label": "2,700",
        "value": 2700
      },
      {
        "label": "2,800",
        "value": 2800
      },
      {
        "label": "2,900",
        "value": 2900
      },
      {
        "label": "3,000",
        "value": 3000
      },
      {
        "label": "3,100",
        "value": 3100
      },
      {
        "label": "3,200",
        "value": 3200
      },
      {
        "label": "3,300",
        "value": 3300
      },
      {
        "label": "3,400",
        "value": 3400
      },
      {
        "label": "3,500",
        "value": 3500
      },
      {
        "label": "3,600",
        "value": 3600
      },
      {
        "label": "3,700",
        "value": 3700
      },
      {
        "label": "3,800",
        "value": 3800
      },
      {
        "label": "3,900",
        "value": 3900
      },
      {
        "label": "4,000",
        "value": 4000
      },
      {
        "label": "4,100",
        "value": 4100
      },
      {
        "label": "4,200",
        "value": 4200
      },
      {
        "label": "4,300",
        "value": 4300
      },
      {
        "label": "4,400",
        "value": 4400
      },
      {
        "label": "4,500",
        "value": 4500
      },
      {
        "label": "4,600",
        "value": 4600
      },
      {
        "label": "4,700",
        "value": 4700
      },
      {
        "label": "4,800",
        "value": 4800
      },
      {
        "label": "4,900",
        "value": 4900
      },
      {
        "label": "5,000",
        "value": 5000
      }
    ]
    let minPriceOptions = JSON.parse(JSON.stringify(priceOptions));
    minPriceOptions.unshift({value:"",label:this.$t('min')})
    let maxPriceOptions = JSON.parse(JSON.stringify(priceOptions));
    maxPriceOptions.unshift({value:"",label:this.$t('max')});

    let cityNeighborhoodOptions = [
      {
        "label": this.$t('rent.Any'),
        "value": ""
      },
      {
        "label": "Acton",
        "value": "Acton"
      },
      {
        "label": "Andover",
        "value": "Andover"
      },
      {
        "label": "- Ballardvale",
        "value": "Andover:Ballardvale"
      },
      {
        "label": "- In Town",
        "value": "Andover:In Town"
      },
      {
        "label": "- Shawsheen",
        "value": "Andover:Shawsheen"
      },
      {
        "label": "- Shawsheen Heights",
        "value": "Andover:Shawsheen Heights"
      },
      {
        "label": "- South Andover",
        "value": "Andover:South Andover"
      },
      {
        "label": "- West Andover",
        "value": "Andover:West Andover"
      },
      {
        "label": "Arlington",
        "value": "Arlington"
      },
      {
        "label": "- Arlington Center",
        "value": "Arlington:Arlington Center"
      },
      {
        "label": "- Arlington Heights",
        "value": "Arlington:Arlington Heights"
      },
      {
        "label": "- Brattle Station",
        "value": "Arlington:Brattle Station"
      },
      {
        "label": "- East Arlington",
        "value": "Arlington:East Arlington"
      },
      {
        "label": "- Morningside",
        "value": "Arlington:Morningside"
      },
      {
        "label": "Ashland",
        "value": "Ashland"
      },
      {
        "label": "Attleboro",
        "value": "Attleboro"
      },
      {
        "label": "- South Attleboro",
        "value": "Attleboro:South Attleboro"
      },
      {
        "label": "Auburn",
        "value": "Auburn"
      },
      {
        "label": "Auburndale",
        "value": "Auburndale"
      },
      {
        "label": "Bedford",
        "value": "Bedford"
      },
      {
        "label": "Belmont",
        "value": "Belmont"
      },
      {
        "label": "- Payson Park",
        "value": "Belmont:Payson Park"
      },
      {
        "label": "- Waverley",
        "value": "Belmont:Waverley"
      },
      {
        "label": "Berkley",
        "value": "Berkley"
      },
      {
        "label": "Berkshire",
        "value": "Berkshire"
      },
      {
        "label": "Berlin",
        "value": "Berlin"
      },
      {
        "label": "Beverly",
        "value": "Beverly"
      },
      {
        "label": "- Beverly Cove",
        "value": "Beverly:Beverly Cove"
      },
      {
        "label": "- Beverly Farms",
        "value": "Beverly:Beverly Farms"
      },
      {
        "label": "- Briscoe",
        "value": "Beverly:Briscoe"
      },
      {
        "label": "- Centerville (Bvly)",
        "value": "Beverly:Centerville (Bvly)"
      },
      {
        "label": "- Montserrat",
        "value": "Beverly:Montserrat"
      },
      {
        "label": "- North Beverly",
        "value": "Beverly:North Beverly"
      },
      {
        "label": "- Prides Crossing",
        "value": "Beverly:Prides Crossing"
      },
      {
        "label": "- Ryal Side",
        "value": "Beverly:Ryal Side"
      },
      {
        "label": "Billerica",
        "value": "Billerica"
      },
      {
        "label": "- East Billerica",
        "value": "Billerica:East Billerica"
      },
      {
        "label": "- North Billerica",
        "value": "Billerica:North Billerica"
      },
      {
        "label": "- Nutting Lake",
        "value": "Billerica:Nutting Lake"
      },
      {
        "label": "- Pattenville",
        "value": "Billerica:Pattenville"
      },
      {
        "label": "- Pinehurst",
        "value": "Billerica:Pinehurst"
      },
      {
        "label": "- South Billerica",
        "value": "Billerica:South Billerica"
      },
      {
        "label": "Boston",
        "value": "Boston"
      },
      {
        "label": "- Allston",
        "value": "Boston:Allston"
      },
      {
        "label": "- Back Bay",
        "value": "Boston:Back Bay"
      },
      {
        "label": "- Bay Village",
        "value": "Boston:Bay Village"
      },
      {
        "label": "- Beacon Hill",
        "value": "Boston:Beacon Hill"
      },
      {
        "label": "- Brighton",
        "value": "Boston:Brighton"
      },
      {
        "label": "- Charlestown",
        "value": "Boston:Charlestown"
      },
      {
        "label": "- Chinatown",
        "value": "Boston:Chinatown"
      },
      {
        "label": "- Dorchester",
        "value": "Boston:Dorchester"
      },
      {
        "label": "- East Boston",
        "value": "Boston:East Boston"
      },
      {
        "label": "- Fenway",
        "value": "Boston:Fenway"
      },
      {
        "label": "- Financial District",
        "value": "Boston:Financial District"
      },
      {
        "label": "- Fort Hill",
        "value": "Boston:Fort Hill"
      },
      {
        "label": "- Hyde Park",
        "value": "Boston:Hyde Park"
      },
      {
        "label": "- Jamaica Plain",
        "value": "Boston:Jamaica Plain"
      },
      {
        "label": "- Kenmore",
        "value": "Boston:Kenmore"
      },
      {
        "label": "- Leather District",
        "value": "Boston:Leather District"
      },
      {
        "label": "- Mattapan",
        "value": "Boston:Mattapan"
      },
      {
        "label": "- Midtown",
        "value": "Boston:Midtown"
      },
      {
        "label": "- Mission Hill",
        "value": "Boston:Mission Hill"
      },
      {
        "label": "- North End",
        "value": "Boston:North End"
      },
      {
        "label": "- Roslindale",
        "value": "Boston:Roslindale"
      },
      {
        "label": "- Roxbury",
        "value": "Boston:Roxbury"
      },
      {
        "label": "- Seaport District",
        "value": "Boston:Seaport District"
      },
      {
        "label": "- South Boston",
        "value": "Boston:South Boston"
      },
      {
        "label": "- South End",
        "value": "Boston:South End"
      },
      {
        "label": "- Theatre District",
        "value": "Boston:Theatre District"
      },
      {
        "label": "- Waterfront",
        "value": "Boston:Waterfront"
      },
      {
        "label": "- West End",
        "value": "Boston:West End"
      },
      {
        "label": "- West Roxbury",
        "value": "Boston:West Roxbury"
      },
      {
        "label": "Boxborough",
        "value": "Boxborough"
      },
      {
        "label": "Boylston",
        "value": "Boylston"
      },
      {
        "label": "Braintree",
        "value": "Braintree"
      },
      {
        "label": "- Braintree Highlands",
        "value": "Braintree:Braintree Highlands"
      },
      {
        "label": "- East Braintree",
        "value": "Braintree:East Braintree"
      },
      {
        "label": "- Five Corners",
        "value": "Braintree:Five Corners"
      },
      {
        "label": "- South Braintree",
        "value": "Braintree:South Braintree"
      },
      {
        "label": "Brookline",
        "value": "Brookline"
      },
      {
        "label": "- Beaconsfield",
        "value": "Brookline:Beaconsfield"
      },
      {
        "label": "- Brookline Hills",
        "value": "Brookline:Brookline Hills"
      },
      {
        "label": "- Brookline Village",
        "value": "Brookline:Brookline Village"
      },
      {
        "label": "- Chestnut Hill",
        "value": "Brookline:Chestnut Hill"
      },
      {
        "label": "- Coolidge Corner",
        "value": "Brookline:Coolidge Corner"
      },
      {
        "label": "- Longwood",
        "value": "Brookline:Longwood"
      },
      {
        "label": "- Reservoir",
        "value": "Brookline:Reservoir"
      },
      {
        "label": "- Washington Square",
        "value": "Brookline:Washington Square"
      },
      {
        "label": "Burlington",
        "value": "Burlington"
      },
      {
        "label": "Cambridge",
        "value": "Cambridge"
      },
      {
        "label": "- Agassiz",
        "value": "Cambridge:Agassiz"
      },
      {
        "label": "- Cambridge Highlands",
        "value": "Cambridge:Cambridge Highlands"
      },
      {
        "label": "- Cambridgeport",
        "value": "Cambridge:Cambridgeport"
      },
      {
        "label": "- Central Square",
        "value": "Cambridge:Central Square"
      },
      {
        "label": "- East Cambridge",
        "value": "Cambridge:East Cambridge"
      },
      {
        "label": "- Harvard Square",
        "value": "Cambridge:Harvard Square"
      },
      {
        "label": "- Huron Village",
        "value": "Cambridge:Huron Village"
      },
      {
        "label": "- Inman Square",
        "value": "Cambridge:Inman Square"
      },
      {
        "label": "- Kendall Square",
        "value": "Cambridge:Kendall Square"
      },
      {
        "label": "- Mid Cambridge",
        "value": "Cambridge:Mid Cambridge"
      },
      {
        "label": "- Neighborhood Nine",
        "value": "Cambridge:Neighborhood Nine"
      },
      {
        "label": "- North Cambridge",
        "value": "Cambridge:North Cambridge"
      },
      {
        "label": "- Porter Square",
        "value": "Cambridge:Porter Square"
      },
      {
        "label": "- Riverside",
        "value": "Cambridge:Riverside"
      },
      {
        "label": "- Wellington-Harrington",
        "value": "Cambridge:Wellington-Harrington"
      },
      {
        "label": "- West Cambridge",
        "value": "Cambridge:West Cambridge"
      },
      {
        "label": "Canton",
        "value": "Canton"
      },
      {
        "label": "- Canton Junction",
        "value": "Canton:Canton Junction"
      },
      {
        "label": "- Ponkapoag",
        "value": "Canton:Ponkapoag"
      },
      {
        "label": "- Springdale",
        "value": "Canton:Springdale"
      },
      {
        "label": "Chelsea",
        "value": "Chelsea"
      },
      {
        "label": "Chestnut Hill",
        "value": "Chestnut Hill"
      },
      {
        "label": "Concord",
        "value": "Concord"
      },
      {
        "label": "- Nine Acre Corner",
        "value": "Concord:Nine Acre Corner"
      },
      {
        "label": "- West Concord",
        "value": "Concord:West Concord"
      },
      {
        "label": "Danvers",
        "value": "Danvers"
      },
      {
        "label": "- Burleys Corner",
        "value": "Danvers:Burleys Corner"
      },
      {
        "label": "- Danvers Center",
        "value": "Danvers:Danvers Center"
      },
      {
        "label": "- Danversport",
        "value": "Danvers:Danversport"
      },
      {
        "label": "- East Danvers",
        "value": "Danvers:East Danvers"
      },
      {
        "label": "- Hathorne",
        "value": "Danvers:Hathorne"
      },
      {
        "label": "- Putnamville",
        "value": "Danvers:Putnamville"
      },
      {
        "label": "- Tapleyville",
        "value": "Danvers:Tapleyville"
      },
      {
        "label": "Dartmouth",
        "value": "Dartmouth"
      },
      {
        "label": "- North Dartmouth",
        "value": "Dartmouth:North Dartmouth"
      },
      {
        "label": "- South Dartmouth",
        "value": "Dartmouth:South Dartmouth"
      },
      {
        "label": "Dedham",
        "value": "Dedham"
      },
      {
        "label": "- Ashcroft",
        "value": "Dedham:Ashcroft"
      },
      {
        "label": "- East Dedham",
        "value": "Dedham:East Dedham"
      },
      {
        "label": "- Endicott",
        "value": "Dedham:Endicott"
      },
      {
        "label": "- Fairbanks Park",
        "value": "Dedham:Fairbanks Park"
      },
      {
        "label": "- Greenlodge",
        "value": "Dedham:Greenlodge"
      },
      {
        "label": "- Oakdale",
        "value": "Dedham:Oakdale"
      },
      {
        "label": "- Readville Manor",
        "value": "Dedham:Readville Manor"
      },
      {
        "label": "- Riverdale",
        "value": "Dedham:Riverdale"
      },
      {
        "label": "- The Manor",
        "value": "Dedham:The Manor"
      },
      {
        "label": "Dover",
        "value": "Dover"
      },
      {
        "label": "East Bridgewater",
        "value": "East Bridgewater"
      },
      {
        "label": "Easton",
        "value": "Easton"
      },
      {
        "label": "Everett",
        "value": "Everett"
      },
      {
        "label": "- Glendale",
        "value": "Everett:Glendale"
      },
      {
        "label": "- Washington Park",
        "value": "Everett:Washington Park"
      },
      {
        "label": "- West Everett",
        "value": "Everett:West Everett"
      },
      {
        "label": "Fitchburg",
        "value": "Fitchburg"
      },
      {
        "label": "- Fitchburg",
        "value": "Fitchburg:Fitchburg"
      },
      {
        "label": "Foxboro",
        "value": "Foxboro"
      },
      {
        "label": "Framingham",
        "value": "Framingham"
      },
      {
        "label": "- Coburnville",
        "value": "Framingham:Coburnville"
      },
      {
        "label": "- Framingham Center",
        "value": "Framingham:Framingham Center"
      },
      {
        "label": "- Lokerville",
        "value": "Framingham:Lokerville"
      },
      {
        "label": "- Nobscot",
        "value": "Framingham:Nobscot"
      },
      {
        "label": "- Saxonville",
        "value": "Framingham:Saxonville"
      },
      {
        "label": "Franklin",
        "value": "Franklin"
      },
      {
        "label": "- Unionville",
        "value": "Franklin:Unionville"
      },
      {
        "label": "- Wadsworth",
        "value": "Franklin:Wadsworth"
      },
      {
        "label": "Gardner",
        "value": "Gardner"
      },
      {
        "label": "Gloucester",
        "value": "Gloucester"
      },
      {
        "label": "- Annisquam",
        "value": "Gloucester:Annisquam"
      },
      {
        "label": "- Bay View",
        "value": "Gloucester:Bay View"
      },
      {
        "label": "- Brier Neck",
        "value": "Gloucester:Brier Neck"
      },
      {
        "label": "- East Gloucester",
        "value": "Gloucester:East Gloucester"
      },
      {
        "label": "- Eastern Point",
        "value": "Gloucester:Eastern Point"
      },
      {
        "label": "- Lanesville",
        "value": "Gloucester:Lanesville"
      },
      {
        "label": "- Magnolia",
        "value": "Gloucester:Magnolia"
      },
      {
        "label": "- Riverdale",
        "value": "Gloucester:Riverdale"
      },
      {
        "label": "- West Gloucester",
        "value": "Gloucester:West Gloucester"
      },
      {
        "label": "Hamilton",
        "value": "Hamilton"
      },
      {
        "label": "- South Hamilton",
        "value": "Hamilton:South Hamilton"
      },
      {
        "label": "Harvard",
        "value": "Harvard"
      },
      {
        "label": "- Harvard",
        "value": "Harvard:Harvard"
      },
      {
        "label": "Huntington",
        "value": "Huntington"
      },
      {
        "label": "Jefferson",
        "value": "Jefferson"
      },
      {
        "label": "Kingston",
        "value": "Kingston"
      },
      {
        "label": "- Indian Pond",
        "value": "Kingston:Indian Pond"
      },
      {
        "label": "Lake Pleasant",
        "value": "Lake Pleasant"
      },
      {
        "label": "Lancaster",
        "value": "Lancaster"
      },
      {
        "label": "- Lancaster",
        "value": "Lancaster:Lancaster"
      },
      {
        "label": "Lawrence",
        "value": "Lawrence"
      },
      {
        "label": "- North Lawrence",
        "value": "Lawrence:North Lawrence"
      },
      {
        "label": "- South Lawrence",
        "value": "Lawrence:South Lawrence"
      },
      {
        "label": "Lexington",
        "value": "Lexington"
      },
      {
        "label": "- North Lexington",
        "value": "Lexington:North Lexington"
      },
      {
        "label": "- Pierces Bridge",
        "value": "Lexington:Pierces Bridge"
      },
      {
        "label": "Lowell",
        "value": "Lowell"
      },
      {
        "label": "- Belvidere",
        "value": "Lowell:Belvidere"
      },
      {
        "label": "- Centralville",
        "value": "Lowell:Centralville"
      },
      {
        "label": "- Downtown",
        "value": "Lowell:Downtown"
      },
      {
        "label": "- Highlands",
        "value": "Lowell:Highlands"
      },
      {
        "label": "- Pawtucketville",
        "value": "Lowell:Pawtucketville"
      },
      {
        "label": "- South Lowell",
        "value": "Lowell:South Lowell"
      },
      {
        "label": "Lynn",
        "value": "Lynn"
      },
      {
        "label": "- Diamond District",
        "value": "Lynn:Diamond District"
      },
      {
        "label": "- East Lynn",
        "value": "Lynn:East Lynn"
      },
      {
        "label": "- Lynn Common",
        "value": "Lynn:Lynn Common"
      },
      {
        "label": "- Lynn Woods",
        "value": "Lynn:Lynn Woods"
      },
      {
        "label": "- Lynnhurst",
        "value": "Lynn:Lynnhurst"
      },
      {
        "label": "- West Lynn",
        "value": "Lynn:West Lynn"
      },
      {
        "label": "- Wyoma",
        "value": "Lynn:Wyoma"
      },
      {
        "label": "Lynnfield",
        "value": "Lynnfield"
      },
      {
        "label": "- South Lynnfield",
        "value": "Lynnfield:South Lynnfield"
      },
      {
        "label": "Malden",
        "value": "Malden"
      },
      {
        "label": "- Bell Rock",
        "value": "Malden:Bell Rock"
      },
      {
        "label": "- Belmont Hill",
        "value": "Malden:Belmont Hill"
      },
      {
        "label": "- Edgeworth",
        "value": "Malden:Edgeworth"
      },
      {
        "label": "- Faulkner",
        "value": "Malden:Faulkner"
      },
      {
        "label": "- Fells",
        "value": "Malden:Fells"
      },
      {
        "label": "- Forestdale",
        "value": "Malden:Forestdale"
      },
      {
        "label": "- Linden",
        "value": "Malden:Linden"
      },
      {
        "label": "- Maplewood",
        "value": "Malden:Maplewood"
      },
      {
        "label": "- Middlesex Fells",
        "value": "Malden:Middlesex Fells"
      },
      {
        "label": "- Oak Grove",
        "value": "Malden:Oak Grove"
      },
      {
        "label": "- West End",
        "value": "Malden:West End"
      },
      {
        "label": "Mansfield",
        "value": "Mansfield"
      },
      {
        "label": "- East Mansfield",
        "value": "Mansfield:East Mansfield"
      },
      {
        "label": "- Robinsonville",
        "value": "Mansfield:Robinsonville"
      },
      {
        "label": "- West Mansfield",
        "value": "Mansfield:West Mansfield"
      },
      {
        "label": "- Whiteville",
        "value": "Mansfield:Whiteville"
      },
      {
        "label": "Marlborough",
        "value": "Marlborough"
      },
      {
        "label": "- East Marlborough",
        "value": "Marlborough:East Marlborough"
      },
      {
        "label": "Medford",
        "value": "Medford"
      },
      {
        "label": "- College Hill",
        "value": "Medford:College Hill"
      },
      {
        "label": "- Medford Hillside",
        "value": "Medford:Medford Hillside"
      },
      {
        "label": "- North Medford",
        "value": "Medford:North Medford"
      },
      {
        "label": "- Tufts University",
        "value": "Medford:Tufts University"
      },
      {
        "label": "- Wellington",
        "value": "Medford:Wellington"
      },
      {
        "label": "- West Medford",
        "value": "Medford:West Medford"
      },
      {
        "label": "Melrose",
        "value": "Melrose"
      },
      {
        "label": "- Fells",
        "value": "Melrose:Fells"
      },
      {
        "label": "- Melrose Highlands",
        "value": "Melrose:Melrose Highlands"
      },
      {
        "label": "- Mount Hood",
        "value": "Melrose:Mount Hood"
      },
      {
        "label": "- Wyoming",
        "value": "Melrose:Wyoming"
      },
      {
        "label": "Milford",
        "value": "Milford"
      },
      {
        "label": "Milton",
        "value": "Milton"
      },
      {
        "label": "Milton Village",
        "value": "Milton Village"
      },
      {
        "label": "Natick",
        "value": "Natick"
      },
      {
        "label": "- East Natick",
        "value": "Natick:East Natick"
      },
      {
        "label": "- Felchville",
        "value": "Natick:Felchville"
      },
      {
        "label": "- Lokerville",
        "value": "Natick:Lokerville"
      },
      {
        "label": "- Morseville",
        "value": "Natick:Morseville"
      },
      {
        "label": "- North Natick",
        "value": "Natick:North Natick"
      },
      {
        "label": "- South Natick",
        "value": "Natick:South Natick"
      },
      {
        "label": "- West Natick",
        "value": "Natick:West Natick"
      },
      {
        "label": "Needham",
        "value": "Needham"
      },
      {
        "label": "- Birds Hill",
        "value": "Needham:Birds Hill"
      },
      {
        "label": "- Charles River Village",
        "value": "Needham:Charles River Village"
      },
      {
        "label": "- Needham Heights",
        "value": "Needham:Needham Heights"
      },
      {
        "label": "- Needham Junction",
        "value": "Needham:Needham Junction"
      },
      {
        "label": "Needham Heights",
        "value": "Needham Heights"
      },
      {
        "label": "New Bedford",
        "value": "New Bedford"
      },
      {
        "label": "- Center",
        "value": "New Bedford:Center"
      },
      {
        "label": "- Far North",
        "value": "New Bedford:Far North"
      },
      {
        "label": "- North",
        "value": "New Bedford:North"
      },
      {
        "label": "- Northwest",
        "value": "New Bedford:Northwest"
      },
      {
        "label": "- South",
        "value": "New Bedford:South"
      },
      {
        "label": "- West",
        "value": "New Bedford:West"
      },
      {
        "label": "New Braintree",
        "value": "New Braintree"
      },
      {
        "label": "New Salem",
        "value": "New Salem"
      },
      {
        "label": "Newbury",
        "value": "Newbury"
      },
      {
        "label": "- Byfield",
        "value": "Newbury:Byfield"
      },
      {
        "label": "- Plum Island",
        "value": "Newbury:Plum Island"
      },
      {
        "label": "Newton",
        "value": "Newton"
      },
      {
        "label": "- Auburndale",
        "value": "Newton:Auburndale"
      },
      {
        "label": "- Chestnut Hill",
        "value": "Newton:Chestnut Hill"
      },
      {
        "label": "- Eliot",
        "value": "Newton:Eliot"
      },
      {
        "label": "- Newton Center",
        "value": "Newton:Newton Center"
      },
      {
        "label": "- Newton Corner",
        "value": "Newton:Newton Corner"
      },
      {
        "label": "- Newton Highlands",
        "value": "Newton:Newton Highlands"
      },
      {
        "label": "- Newton Lower Falls",
        "value": "Newton:Newton Lower Falls"
      },
      {
        "label": "- Newton Upper Falls",
        "value": "Newton:Newton Upper Falls"
      },
      {
        "label": "- Newtonville",
        "value": "Newton:Newtonville"
      },
      {
        "label": "- Nonantum",
        "value": "Newton:Nonantum"
      },
      {
        "label": "- Thompsonville",
        "value": "Newton:Thompsonville"
      },
      {
        "label": "- Waban",
        "value": "Newton:Waban"
      },
      {
        "label": "- West Newton",
        "value": "Newton:West Newton"
      },
      {
        "label": "- Woodland Station",
        "value": "Newton:Woodland Station"
      },
      {
        "label": "North Attleboro",
        "value": "North Attleboro"
      },
      {
        "label": "North Dartmouth",
        "value": "North Dartmouth"
      },
      {
        "label": "North Reading",
        "value": "North Reading"
      },
      {
        "label": "- Martins Pond",
        "value": "North Reading:Martins Pond"
      },
      {
        "label": "- West Village",
        "value": "North Reading:West Village"
      },
      {
        "label": "Norton",
        "value": "Norton"
      },
      {
        "label": "Norwood",
        "value": "Norwood"
      },
      {
        "label": "- Orleans (village)",
        "value": "Orleans:Orleans (village)"
      },
      {
        "label": "Peabody",
        "value": "Peabody"
      },
      {
        "label": "- Needham Corner",
        "value": "Peabody:Needham Corner"
      },
      {
        "label": "- South Peabody",
        "value": "Peabody:South Peabody"
      },
      {
        "label": "- West Peabody",
        "value": "Peabody:West Peabody"
      },
      {
        "label": "Plymouth",
        "value": "Plymouth"
      },
      {
        "label": "- Cedar Bushes",
        "value": "Plymouth:Cedar Bushes"
      },
      {
        "label": "- Cedarville",
        "value": "Plymouth:Cedarville"
      },
      {
        "label": "- Chiltonville",
        "value": "Plymouth:Chiltonville"
      },
      {
        "label": "- Cordage",
        "value": "Plymouth:Cordage"
      },
      {
        "label": "- Ellisville",
        "value": "Plymouth:Ellisville"
      },
      {
        "label": "- Long Pond",
        "value": "Plymouth:Long Pond"
      },
      {
        "label": "- Manomet",
        "value": "Plymouth:Manomet"
      },
      {
        "label": "- Nameloc Heights",
        "value": "Plymouth:Nameloc Heights"
      },
      {
        "label": "- North Plymouth",
        "value": "Plymouth:North Plymouth"
      },
      {
        "label": "- Pinehills",
        "value": "Plymouth:Pinehills"
      },
      {
        "label": "- Pondville",
        "value": "Plymouth:Pondville"
      },
      {
        "label": "- Saquish",
        "value": "Plymouth:Saquish"
      },
      {
        "label": "- South Plymouth",
        "value": "Plymouth:South Plymouth"
      },
      {
        "label": "- South Pond",
        "value": "Plymouth:South Pond"
      },
      {
        "label": "- Vallersville",
        "value": "Plymouth:Vallersville"
      },
      {
        "label": "- West Plymouth",
        "value": "Plymouth:West Plymouth"
      },
      {
        "label": "Quincy",
        "value": "Quincy"
      },
      {
        "label": "- Adams Shore",
        "value": "Quincy:Adams Shore"
      },
      {
        "label": "- Atlantic",
        "value": "Quincy:Atlantic"
      },
      {
        "label": "- Germantown",
        "value": "Quincy:Germantown"
      },
      {
        "label": "- Houghs Neck",
        "value": "Quincy:Houghs Neck"
      },
      {
        "label": "- Marina Bay",
        "value": "Quincy:Marina Bay"
      },
      {
        "label": "- Merrymount",
        "value": "Quincy:Merrymount"
      },
      {
        "label": "- Montclair",
        "value": "Quincy:Montclair"
      },
      {
        "label": "- Norfolk Downs",
        "value": "Quincy:Norfolk Downs"
      },
      {
        "label": "- North Quincy",
        "value": "Quincy:North Quincy"
      },
      {
        "label": "- Quincy Center",
        "value": "Quincy:Quincy Center"
      },
      {
        "label": "- Quincy Neck",
        "value": "Quincy:Quincy Neck"
      },
      {
        "label": "- Quincy Point",
        "value": "Quincy:Quincy Point"
      },
      {
        "label": "- Rock Island",
        "value": "Quincy:Rock Island"
      },
      {
        "label": "- South Quincy",
        "value": "Quincy:South Quincy"
      },
      {
        "label": "- Squantum",
        "value": "Quincy:Squantum"
      },
      {
        "label": "- West Quincy",
        "value": "Quincy:West Quincy"
      },
      {
        "label": "- Wollaston",
        "value": "Quincy:Wollaston"
      },
      {
        "label": "- Wollaston Heights",
        "value": "Quincy:Wollaston Heights"
      },
      {
        "label": "Reading",
        "value": "Reading"
      },
      {
        "label": "Revere",
        "value": "Revere"
      },
      {
        "label": "- Beachmont",
        "value": "Revere:Beachmont"
      },
      {
        "label": "- Crescent Beach",
        "value": "Revere:Crescent Beach"
      },
      {
        "label": "- Franklin Park",
        "value": "Revere:Franklin Park"
      },
      {
        "label": "- Oak Island",
        "value": "Revere:Oak Island"
      },
      {
        "label": "- Point of Pines",
        "value": "Revere:Point of Pines"
      },
      {
        "label": "- Revere Beach",
        "value": "Revere:Revere Beach"
      },
      {
        "label": "Rochester",
        "value": "Rochester"
      },
      {
        "label": "Salem",
        "value": "Salem"
      },
      {
        "label": "- Bakers Island",
        "value": "Salem:Bakers Island"
      },
      {
        "label": "- Forest River",
        "value": "Salem:Forest River"
      },
      {
        "label": "- Gallows Hill",
        "value": "Salem:Gallows Hill"
      },
      {
        "label": "- Salem Neck",
        "value": "Salem:Salem Neck"
      },
      {
        "label": "- Salem Willows",
        "value": "Salem:Salem Willows"
      },
      {
        "label": "- South Salem",
        "value": "Salem:South Salem"
      },
      {
        "label": "- Winter Island",
        "value": "Salem:Winter Island"
      },
      {
        "label": "Saugus",
        "value": "Saugus"
      },
      {
        "label": "Sharon",
        "value": "Sharon"
      },
      {
        "label": "Sherborn",
        "value": "Sherborn"
      },
      {
        "label": "- South Sherborn",
        "value": "Sherborn:South Sherborn"
      },
      {
        "label": "- Whitneys",
        "value": "Sherborn:Whitneys"
      },
      {
        "label": "Somerville",
        "value": "Somerville"
      },
      {
        "label": "- Ball Square",
        "value": "Somerville:Ball Square"
      },
      {
        "label": "- Davis Square",
        "value": "Somerville:Davis Square"
      },
      {
        "label": "- East Somerville",
        "value": "Somerville:East Somerville"
      },
      {
        "label": "- Inman Square",
        "value": "Somerville:Inman Square"
      },
      {
        "label": "- Powderhouse Square",
        "value": "Somerville:Powderhouse Square"
      },
      {
        "label": "- Prospect Hill",
        "value": "Somerville:Prospect Hill"
      },
      {
        "label": "- Spring Hill",
        "value": "Somerville:Spring Hill"
      },
      {
        "label": "- Teele Square",
        "value": "Somerville:Teele Square"
      },
      {
        "label": "- Union Square",
        "value": "Somerville:Union Square"
      },
      {
        "label": "- West Somerville",
        "value": "Somerville:West Somerville"
      },
      {
        "label": "- Winter Hill",
        "value": "Somerville:Winter Hill"
      },
      {
        "label": "South Dartmouth",
        "value": "South Dartmouth"
      },
      {
        "label": "Springfield",
        "value": "Springfield"
      },
      {
        "label": "- East Forest Park",
        "value": "Springfield:East Forest Park"
      },
      {
        "label": "- East Springfield",
        "value": "Springfield:East Springfield"
      },
      {
        "label": "- Forest Park",
        "value": "Springfield:Forest Park"
      },
      {
        "label": "- Hill McKnight",
        "value": "Springfield:Hill McKnight"
      },
      {
        "label": "- Indian Orchard",
        "value": "Springfield:Indian Orchard"
      },
      {
        "label": "- Liberty Heights",
        "value": "Springfield:Liberty Heights"
      },
      {
        "label": "- N End Brightwood",
        "value": "Springfield:N End Brightwood"
      },
      {
        "label": "- Pine Pt Bstn Rd",
        "value": "Springfield:Pine Pt Bstn Rd"
      },
      {
        "label": "- Sixteen Acres",
        "value": "Springfield:Sixteen Acres"
      },
      {
        "label": "- So End Central",
        "value": "Springfield:So End Central"
      },
      {
        "label": "Stoneham",
        "value": "Stoneham"
      },
      {
        "label": "Stoughton",
        "value": "Stoughton"
      },
      {
        "label": "Sudbury",
        "value": "Sudbury"
      },
      {
        "label": "- North Sudbury",
        "value": "Sudbury:North Sudbury"
      },
      {
        "label": "- South Sudbury",
        "value": "Sudbury:South Sudbury"
      },
      {
        "label": "Swampscott",
        "value": "Swampscott"
      },
      {
        "label": "Waban",
        "value": "Waban"
      },
      {
        "label": "Wakefield",
        "value": "Wakefield"
      },
      {
        "label": "Walpole",
        "value": "Walpole"
      },
      {
        "label": "Waltham",
        "value": "Waltham"
      },
      {
        "label": "Watertown",
        "value": "Watertown"
      },
      {
        "label": "- Bemis",
        "value": "Watertown:Bemis"
      },
      {
        "label": "- East Watertown",
        "value": "Watertown:East Watertown"
      },
      {
        "label": "- Mount Auburn",
        "value": "Watertown:Mount Auburn"
      },
      {
        "label": "Wellesley",
        "value": "Wellesley"
      },
      {
        "label": "- Babson Park",
        "value": "Wellesley:Babson Park"
      },
      {
        "label": "- Newton Lower Falls",
        "value": "Wellesley:Newton Lower Falls"
      },
      {
        "label": "- Overbrook",
        "value": "Wellesley:Overbrook"
      },
      {
        "label": "- Wellesley Farms",
        "value": "Wellesley:Wellesley Farms"
      },
      {
        "label": "- Wellesley Fells",
        "value": "Wellesley:Wellesley Fells"
      },
      {
        "label": "- Wellesley Hills",
        "value": "Wellesley:Wellesley Hills"
      },
      {
        "label": "Wellesley Hills",
        "value": "Wellesley Hills"
      },
      {
        "label": "Wenham",
        "value": "Wenham"
      },
      {
        "label": "West Bridgewater",
        "value": "West Bridgewater"
      },
      {
        "label": "West Medford",
        "value": "West Medford"
      },
      {
        "label": "West Newbury",
        "value": "West Newbury"
      },
      {
        "label": "West Springfield",
        "value": "West Springfield"
      },
      {
        "label": "Weston",
        "value": "Weston"
      },
      {
        "label": "- Hastings",
        "value": "Weston:Hastings"
      },
      {
        "label": "- Silver Hill",
        "value": "Weston:Silver Hill"
      },
      {
        "label": "Westwood",
        "value": "Westwood"
      },
      {
        "label": "Wilmington",
        "value": "Wilmington"
      },
      {
        "label": "Winchester",
        "value": "Winchester"
      },
      {
        "label": "- Symmes Corner",
        "value": "Winchester:Symmes Corner"
      },
      {
        "label": "- Wedgemere",
        "value": "Winchester:Wedgemere"
      },
      {
        "label": "- Winchester Highlands",
        "value": "Winchester:Winchester Highlands"
      },
      {
        "label": "Windsor",
        "value": "Windsor"
      },
      {
        "label": "Winthrop",
        "value": "Winthrop"
      },
      {
        "label": "- Belle Isle",
        "value": "Winthrop:Belle Isle"
      },
      {
        "label": "- Cottage Hill",
        "value": "Winthrop:Cottage Hill"
      },
      {
        "label": "- Cottage Park",
        "value": "Winthrop:Cottage Park"
      },
      {
        "label": "- Point Shirley",
        "value": "Winthrop:Point Shirley"
      },
      {
        "label": "- Winthrop Beach",
        "value": "Winthrop:Winthrop Beach"
      },
      {
        "label": "- Winthrop Highlands",
        "value": "Winthrop:Winthrop Highlands"
      },
      {
        "label": "Woburn",
        "value": "Woburn"
      },
      {
        "label": "Wrentham",
        "value": "Wrentham"
      }
    ]
    return {
      currentIndex:0,
      detailDialogStatus2:true,
      cityNeighborhoodOptions:cityNeighborhoodOptions,
      filter:{
        stree_name:{
          value:"",
          label:this.$t('rent.Enterastreename')
        },
        city_neighborhood:{
          value:"",
          label:this.$t('rent.CityHeighborhood'),
          options:cityNeighborhoodOptions
        },
        bedrooms:{
          label:this.$t('filter.bedrooms.label'),
          value:"",
          options:[
            {label: this.$t('rent.Any'), value: ''},
            {label: '1+', value: 1},
            {label: '2+', value: 2},
            {label: '3+', value: 3},
            {label: '4+', value: 4},
            {label: '5+', value: 5}
          ]
        },
        bathrooms:{
          label:this.$t('filter.bathrooms.label'),
          value:"",
          options:[
            {label: this.$t('rent.Any'), value: ''},
            {label: '1+', value: "1"},
            {label: '2+', value: "2"},
            {label: '3+', value: "3"},
            {label: '4+', value: "4"},
            {label: '5+', value: "5"}
          ]
        },
        price:{
          label:this.$t('filter.price.label'),
          value:"",
          minvalue:"",
          maxvalue:"",
          minoptions:minPriceOptions,
          maxoptions:maxPriceOptions,
          initoptions:priceOptions,
          initminopts:minPriceOptions,
          initmaxopts:maxPriceOptions,
          show:true
        },
        pet:{
          label:this.$t('rent.Pet'),
          value:"",
          options:[
            {label:this.$t('rent.Any'),value:""},
            {label:this.$t('rent.CatOk'),value:"cat"},
            {label:this.$t('rent.DogOk'),value:"dog"},
            {label:this.$t('rent.CatDogOk'),value:"friendly"}
          ]
        },
        photo:{
          label:this.$t('rent.withphotosonly'),
          value:""
        },
        avail:{
          label:this.$t('avail'),
          value:{from:"",to:""}
        }
      },
      houseList:[],
      houseLatLng:[],
      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos:null,
      infoWinOpen:false,
      currentMidx:null,
      page:{
        number:1,
        size:10,
        count:0
      },
      query:{
        action:this.$route.query.action
      },
      isloading:false,
      viewStyle:{
        height:"calc(100% - 60px)"
      },
      detailDialogStatus:false,
      currentDetailInfo:{
        StreetNumber:"",
        StreetName:"",
        City:"",
        Neighborhood:"",
        VirtualTours:{
          VirtualTour:[]
        },
        Parking:{}
      },
      swiperOptions:{
        slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 2,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        zoom:1
      },
      availRang:""
    }
  },
  created() {
    // this.requestRentRentalsSearch(this.getSearchParams);
  },
  computed:{
    getSearchParams(){
      return {
        sort_dir:"desc",
        sort_name:"availDate",
        stree_name:this.filter.stree_name.value || undefined,
        min_bed:this.filter.bedrooms.value || undefined,
        baths:this.filter.bathrooms.value || undefined,
        city_neighborhood:this.filter.city_neighborhood.value || undefined,
        min_rent:this.filter.price.minvalue || undefined,
        max_rent:this.filter.price.maxvalue || undefined,
        pet:this.filter.pet.value || undefined,
        photo:this.filter.photo.value || undefined,
        page_count:this.page.size,
        page_index:this.page.number,
        avail_from:this.filter.avail.value.from,
        avail_to:this.filter.avail.value.to
      }
    }
  },
  watch:{
    "filter.price.minvalue"(n,o){
      this.handlePriceSelect(n,this.filter.price.maxvalue);
    },
    "filter.price.maxvalue"(n,o){
      this.handlePriceSelect(this.filter.price.minvalue,n);
    },
    "$route"(n,o){
      this.houseList = [];
      this.query.action = n.query && n.query.action;
      this.resetLoad()
    },
    "filter.avail.value"(n,o){
      if(n){
        this.availRang = n.from + "-" + n.to;
      }
      if(!n.from && !n.to){
        this.availRang = ""
      }
      this.resetLoad();
    }
  },
  methods:{
    openVT(VT){
      window.open(VT)
    },
    streeNameInput(){
      this.resetLoad()
    },
    clearNameEvent(){
      this.filter.stree_name.value = "";
      this.resetLoad()
    },
    bedroomsInput(value){
      this.filter.bedrooms.label = `${this.$t('filter.bedrooms.label')}${value ? "("+value+")" : ''}`;
      this.$refs.bedroomsRef.hide();
      this.resetLoad()
    },
    bathroomsInput(value){
      this.filter.bathrooms.label = `${this.$t('filter.bathrooms.label')}${value ? "("+value+")" : ''}`;
      this.$refs.bathroomsRef.hide();
      this.resetLoad()
    },
    gMapMarkerClick(marker){
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;
      if (this.currentMidx == marker.id) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = marker.id;
      }
    },
    gMapMarkerOver(marker){
      console.log(marker)
    },
    async clickDialog(house,index){
      let houseID = house.ID;
      let marker = this.houseLatLng[index];
      this.currentIndex = index;
      this.detailDialogStatus = true;
      this.$q.loading.show();
      let res = await this.$api.rentRentalsSearchById({
        listing_id:houseID
      });
      this.$q.loading.hide();
      let YGLResponse = res.YGLResponse;
      let Listings = YGLResponse?.Listings;
      let Listing = Listings?.Listing;
      let currentDetailInfo = Listing && (Listing.length && Listing[0] || Listing) || {};
      let VirtualTour = currentDetailInfo.VirtualTours?.VirtualTour;
      if(Object.prototype.toString.call(VirtualTour) === "[object String]"){
        let tmpary = [VirtualTour];
        currentDetailInfo.VirtualTours.VirtualTour = tmpary;
      }
      this.currentDetailInfo = currentDetailInfo;
      this.gMapMarkerClick(marker);
    },
    phouseMouseEnter(index){
      let marker = this.houseLatLng[index];
      this.gMapMarkerClick(marker);
    },

    priceMinSelectInput(){

    },
    priceMaxSelectInput(){

    },
    handlePriceSelect(minprice,maxprice){
      let initminopts = this.filter.price.initminopts;
      let initmaxopts = this.filter.price.initmaxopts;
      let currentMinOption = initminopts.filter(function(item){
        return item.value === minprice
      })[0];
      let currentMaxOption = initmaxopts.filter(function(item){
        return item.value === maxprice
      })[0];
      let maxOptions = initmaxopts.filter(function(item){
        let id = item.value;
        if(id === ""){
          return true;
        }
        return id > minprice;
      });

      this.filter.price.maxoptions = maxOptions;
      let label = "";
      if(minprice === "" && maxprice === ""){
        label = `${this.$t('filter.price.label')}`;
        this.filter.price.value = "";
      }else if(minprice === "" && maxprice > 0){
        label = `<${currentMaxOption.label}`;
        this.filter.price.value = `${maxprice}-`;
      }else if((minprice > 0 && maxprice === "" )|| (minprice === maxprice)) {
        label = `>${currentMinOption.label}`;
        this.filter.price.value = `${minprice}:${maxprice}`;
      }else if(minprice > 0 && maxprice > 0 && minprice < maxprice){
        label = `${currentMinOption.label} - ${currentMaxOption.label}`;
        this.filter.price.value = `${minprice}:${maxprice}`;
      }else if(minprice > maxprice && maxprice > 0){
        label = `${currentMaxOption.label} - ${currentMinOption.label}`;
        this.filter.price.minvalue = maxprice;
        this.filter.price.maxvalue = minprice;
        this.filter.price.value = `${maxprice}:${minprice}`;
      }
      this.filter.price.label = label;
      this.resetLoad()
    },
    cityNeighborhoodSelctInput(){
      this.resetLoad()
    },
    petSelctInput(){
      this.resetLoad()
    },
    photoCheckInput(){
      console.log('photo:this.filter.photo.value',this.filter.photo.value)
      
      this.resetLoad()
    },
    async requestRentRentalsSearch(parsme,type){
      let resultList = [];
      let result;

      let res = await this.$api.rentRentalsSearch(parsme);

      let YGLResponse = res.YGLResponse;
      result = YGLResponse;
      if(YGLResponse && YGLResponse.Listings){
        let Listings = YGLResponse.Listings;
        resultList = Object.prototype.toString.call(Listings.Listing) === '[object Object]' ? [Listings.Listing] : Listings.Listing;
        // resultList.forEach(item=>{
        //   item.zzUpdateDate = item.releaseDate ? `${item.releaseDate}${this.$t('beforeUpdate')}` : this.$t('justUpdate')
        // })
      }
      return {
        resultList, result
      }
    },
    async houseListLoad(index, done){
      let params = {};
      let houseList = [];
      let resultRes;
      if(index === 1){
        this.houseList = []
      }
      if(this.query.action !== 'favourite'){
        params = Object.assign({},this.getSearchParams,{
          page_index:index
        });
      }else{
        params = {
          pageNo:index
        }
      }
      this.isloading = true;
      if(this.query.action !== 'favourite'){
        let {resultList, result} = await this.requestRentRentalsSearch(params);
        console.log("resultList:",resultList)
        houseList = resultList;
        resultRes = result;
      }else{
        let {resultList, result} = await this.requestGetFavouriteRentList(params);
        houseList = resultList;
        resultRes = result;
      }

      this.isloading = false;
      if(index > 1){
        this.houseList = this.houseList.concat(houseList);
      }else{
        this.houseList = houseList;
      }

      let houseLatLng = this.houseList.map((listing)=>{
        return {
          position:{
            lat:listing.Latitude,
            lng:listing.Longitude,
          },
          infoText:`<div class="infowindow__box">
                        <div class="infowindow__box--image"><img src="${listing.Photos && listing.Photos.Photo}"></div>
                        <div class="infowindow__box--name">${listing.StreetName}</div>
                    </div>`,
          id:listing.ID
        }
      });
      this.houseLatLng = houseLatLng;

      let pagingCount = resultRes?.PageCount || 10;
      let Total = resultRes?.Total || 0;
      let PageIndex = resultRes?.PageIndex;
      done(index >= (Total - 1 / pagingCount) + 1 || !resultRes.Listings);
    },
    async requestGetFavouriteRentList(params){
      let resultList = [];
      let res = await this.$api.requestGetFavouriteRentList(params);
      let code = res.code;
      let result;
      if(code === 1000){
        let data = res?.data;
        let total = data?.total;
        let list = data?.list;
        let listResult = list?.YGLResponse;
        let Listings = listResult?.Listings;
        let Listing = Listings?.Listing;
        resultList = Listing || [];
        result = listResult;
        // resultList.forEach(item=>{
        //   item.zzUpdateDate = `${item.releaseDate}${this.$t('beforeUpdate')}`
        // })
      }
      return {
        resultList,
        result
      };
    },
    async collectHouse(id,index,action){
      try {
        let res = await this.$api[action === "favourite" ? 'requestCollectionCancelRentListings' : 'requestCollectionSaveRentListings']({
          rentId:id
        });
        if(action === "favourite"){
          this.houseList.splice(index,1);
        }
      }catch (e){

      }
    },
    // filterFn (val, update) {
    //   console.log('val',val)
    //   let _this = this;
    //   if (val === '') {
    //     update(() => {
    //       _this.filter.city_neighborhood.options = _this.cityNeighborhoodOptions
    //     })
    //     return
    //   }

    //   update(() => {

    //     const needle = val.toLowerCase()
    //     this.filter.city_neighborhood.options = this.cityNeighborhoodOptions.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
    //   })
    // },
    resetLoad(){
      this.$refs.infinitescroll.reset();
      this.$refs.infinitescroll.resume();
      this.$refs.infinitescroll.trigger();
    },
    availRangClear(){
      this.filter.avail.value = {
        from: "",
        to: ""
      };
    }
  },
  mounted() {
    this.viewStyle.height = (document.body.clientHeight - 180) + "px";
    let _this = this;
    window.onresize = function(){
      _this.viewStyle.height = (document.body.clientHeight - 180) + "px";
    }
  }
};
</script>

<style scoped lang="scss">
@import "../assets/css/list.scss";

.searchNum{
  display: inline-block;
}
.label_title{
  display: inline-block;
  width: 80px;
  text-align: left;
}
.moresearchModule{
  clear: both;
  display: block;
    padding: 20px;
    ul li{
      color: #A6A6A6;
      height:30px;
      line-height:30px;
      margin-bottom:10px;
    }
  }
  .title{
    color: #443227;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 16px 0 16px;
    box-sizing: content-box;
  }
  .phouse__search--filterlist{
    .q-btn{
      margin-right: 0 !important;
    }
  }
  .checkphoto__box{
    height: 40px;
  }
  .phouse__item--info--updateDate{
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 12px;
    color: #fff;
    background: #4599d1;
    padding: 5px 3px;
    border-radius: 5px;
  }
.swiper-wrapper{
  position: relative;
  .swiper-slide{
    height: calc((100% - 30px) / 2);
    img{
      height: 100%;
    }
  }
}
</style>
